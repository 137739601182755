
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* ------------------ POST FEED ----------------- */
label.required::after {
  content: ' *';
}

.post-video-container {
  display: flex;
  justify-content: center;
  max-height: 500px;
  position: relative;
  padding: 56.25% 1rem 1rem;
  align-items: center;
}

.post-video-iframe {
  object-fit: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.post-image-container {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
}

.post-image {
  flex-shrink: 0;
  width: 100%;
  object-fit: contain;
  max-height: 500px;
}

.post-delete-media {
  margin-left: auto;
  position: absolute;
  top: 20px;
  right: 20px;
  color: black;
}

.pointer-top-right::after {
  content: "";
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  background: white;
  transform: rotate(45deg);
  top: -5px;
  right: 15px;
  border-radius: 2px;
}

/*This provides a little mouse bridge between the diamond button and the diamond selector box*/
.diamond-holder:hover::after {
  content: ".";
  opacity: 0;
  display: block;
  height: 25px;
  width: 325px;
  position: absolute;
  bottom: 20px;
  left: -145px;
}

.diamond-selector {
  display: none;
}

.diamond-holder:hover > .diamond-selector {
  display: block;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate {
  animation: rotation 2s infinite linear;
}

/* ------------------ PROJECT DETAILS ----------------- */
.project-description h1,
.project-description h2,
.project-description h3,
.project-description h4,
.project-description h5,
.project-description h6 {
  @apply font-sans;
  font-weight: 600;
  @apply text-muted-foreground;
  line-height: 1.5;
}

code { color: #888; font-size: 16px; }

.project-description h1,
.project-description h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
.project-description p {
  @apply text-foreground;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 24px;
  line-height: 1.6;
}
.project-description p:last-of-type {
  margin-bottom: 0px;
}
.project-description a {
  text-decoration: underline;
}
.project-description a:hover {
  color: #05f;
}
.project-description ol,
.project-description ul {
  margin: 0 12px 12px;
  padding: 0 12px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  list-style-type: initial;
}
.project-description ul > li > ul {
  margin: 0 12px;
  padding: 0 12px;
}
/* ------------------ Homepage Illustrations ----------------- */
.img--planet-bg {
  background-image: url("./assets/img/image-planet-bg.webp");
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
  background-size: 100%;
  position: absolute;
  opacity: .1;
  left: 0;
  top: -100px;
  z-index: 0;
}
.img--planet-1 {
  position: absolute;
  width: 220px;
  height: auto;
  top: -80px;
  right: 80px;
}
.img--planet-2 {
  position: absolute;
  width: 140px;
  height: auto;
  top: -100px;
  opacity: 0.2;
  left: -10px;
}
.img--planet-3 {
  position: absolute;
  width: 340px;
  height: auto;
  top: 50px;
  left: -10px;
  opacity: 0.1;
}
.img--planet-4 {
  position: absolute;
  width: 170px;
  height: auto;
  top: 180px;
  right: -10px;
  opacity: 0.1;
}
.img--planet-5 {
  position: absolute;
  width: 350px;
  height: auto;
  top: 280px;
  right: -100px;
}
.img--planet-6 {
  position: absolute;
  width: 150px;
  height: auto;
  top: -10px;
  left: 120px;
}
.img--planet-7 {
  position: absolute;
  width: 220px;
  height: auto;
  top: 160px;
  right: 200px;
  opacity: 0.4;
}
.img--planet-8 {
  position: absolute;
  width: 150px;
  height: auto;
  top: 110px;
  left: -200px;
}
.img--planet-9 {
  position: absolute;
  width: 50px;
  height: auto;
  top: -110px;
  left: 90px;
  animation: moveUpDown 1.8s infinite  ease-in-out;
}
.img--planet-10 {
  position: absolute;
  width: 70px;
  height: auto;
  top: 390px;
  left: 160px;
}
.img--planet-15 {
  animation: moveUpDown 1.5s infinite  ease-in-out;
}
.img--rock-2 {
  position: absolute;
  width: 100px;
  height: auto;
  top: 280px;
  left: 100px;
  opacity: 0.4;
}
.img--stars-1 {
  position: absolute;
  width: 1200px;
  height: auto;
  top: -90px;
  right: 50px;
  z-index: 0;
  opacity: 0.2;
}
.img--stars-cta {
  position: absolute;
  width: 1200px;
  height: auto;
  top: -10px;
  right: 120px;
  z-index: 0;
  opacity: 0.1;
}
/* ------------------ Animations ----------------- */
@keyframes moveUpDown {
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}


/* ------------------ Charts ----------------- */
.discover-chart-max-width {
  max-width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ------------------ Comment Threads ----------------- */
.comment-thread {
  position: relative;
}
/* ------------------ Descriptions ----------------- */
.description-links a {
  color: #06f;
}
.description-links a:hover {
  text-decoration: underline;
}
/* ------------------ Messages ----------------- */
.message-overflow {
  max-width: 250px;
  overflow-wrap: break-word;
}
.message-overflow a {
  color: #93c5fd;
}

.message-overflow-self a {
  color: black;
  font-weight: 700;
}

.message-overflow-wide {
  max-width: 500px;
  overflow-wrap: break-word;
}

.messages-author {
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.author-message-bubble div {
  background: black;
  color: white;
}

.message-textarea label {
  display: none;
}

.messages-area {
  max-height: 750px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
}

.message-item:first-child {
  margin-bottom: 0px;
}

@media (max-width: 500px) {
  .img--planet-1 {
    width: 120px;
    top: -180px;
    right: -10px;
  }
  .img--planet-bg {
    top: -500px;
  }
}
@media (max-width: 1024px) {
  .img--planet-1 {
    width: 120px;
    top: -150px;
    right: -10px;
  }
  .img--planet-bg {
    top: -500px;
  }
  .img--planet-4 {
    display: none;
  }
  .img--planet-6 {
    display: none;
  }
  .img--planet-7 {
    display: none;
  }
  .message-overflow, .message-overflow-wide {
    max-width: calc(100vw - 30px);
  }
}

.wrap-text-balance {
  text-wrap: balance;
}

.swiper-button-next, .swiper-button-prev {
  position: absolute !important;
  background: rgba(0, 0, 0, 0.398) !important;
  border-radius: 100% !important;
  border: 0 !important;
  width: 40px !important;
  height: 40px !important;
  color: white !important;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 20px !important;
}

@keyframes table-loader {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* ------------------ Homepage landing ----------------- */
.typewriter-cursor {
  animation: blink 1s infinite;
}
.typewriter-text {
  background: linear-gradient(to right, #ff8a00 0%, #dd4c4f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

/* ------------------ Price Update Flash ----------------- */

@keyframes flashPrice {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 8px currentColor;
  }
  100% {
    opacity: 1;
    text-shadow: none;
  }
}

.price-flash {
  animation: flashPrice 0.5s ease-out;
}
